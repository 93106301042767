<template>
  <div class="ui-loader" :style="style">
    <slot name="loader-content">
      <i class="pi pi-spin pi-spinner"></i>
    </slot>
  </div>
</template>

<script src="./BaseLoader.ts" lang="ts"/>
<style src="./BaseLoader.scss" lang="scss" />
