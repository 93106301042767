<template>
  <BaseLoader>
    <template #loader-content>
      <div class="p-d-flex p-dir-col p-align-center p-justify-center">
        <i v-if="message === FORM_DICTIONARY.ACTIVE_REDIRECTING" class="pi pi-spin pi-spinner"></i>
        <div>{{ message }}</div>
      </div>
    </template>
  </BaseLoader>
</template>

<script src="./ExternalRequestRedirect.ts" lang="ts"/>
<style src="./ExternalRequestRedirect.scss" lang="scss"/>
