import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import BaseLoader from '@/components/BaseComponents/BaseLoader/BaseLoader.vue';
import { RequestHttpService } from '@/services/requestService';
import ROUTE_NAMES from '@/router/route.names.json';
import FORM_DICTIONARY from '@/dictionaries/validation/dictionary.json';
import { switchError } from '@/services/utils/switchError';

const requestHttpService = new RequestHttpService();

export default defineComponent({
  name: 'ExternalRequestRedirect',

  components: {
    BaseLoader
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const message = ref(FORM_DICTIONARY.ACTIVE_REDIRECTING);

    onMounted(async() => {
      try {
        const { data } = await requestHttpService.getIdByExternalRequest(route.params.id as string);
        router.push({
          name: ROUTE_NAMES.PAGE_REQUEST,
          params: { id: data.requestId }
        });
      } catch (e) {
        switchError(e, FORM_DICTIONARY.REQUEST_NOT_FOUND);
        message.value = FORM_DICTIONARY.REQUEST_NOT_FOUND;
      }
    });

    return {
      FORM_DICTIONARY,
      message
    };
  }
});
