import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseLoader',

  props: {
    backgroundColorRGBA: {
      type: String,
      default: 'rgba(1,1,1, .3)'
    }
  },
  setup(props) {
    const style = `background-color: ${props.backgroundColorRGBA}`;
    return { style };
  }
});
